<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs
          background-color="transparent"
          centered
          class="mb-5"
          show-arrows
        >
          <v-tab
            v-for="(category, i) in categories"
            :key="i"
            @click="select(category)"
          >
            <span v-text="category.text" />
          </v-tab>
        </v-tabs>

        <v-responsive min-height="700">
          <v-container>
            <transition-group
              tag="v-row"
              name="fade-transition"
            >
              <v-col
                v-for="plant in computedplants"
                :key="plant.img"
                cols="12"
                class="gallery-card"
                md="4"
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      :img="`/static/${plant.img}`"
                      class="white--text overflow-hidden"
                      dark
                      height="300"
                      hover
                      tile
                      @click="overlay = plant.img"
                    >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                          <v-icon large>
                            mdi-magnify
                          </v-icon>
                          <!-- <span style="font-size: 25px;">
                              <font-awesome-icon
                                class="icon-large"
                                :icon="['fas', 'location-arrow']"
                              />
                            </span> -->
                        </v-overlay>
                      </v-fade-transition>
                      <v-slide-y-reverse-transition>
                        <v-footer
                          :key="plant.img"
                          color="rgba(0,0,0,0.3), rgba(0,0,0,0.3)"
                          absolute
                          height="75"
                        >
                          <v-card-text style="color: white">
                            {{ plant.name }}
                            <p class="font-italic">
                              {{ plant.title }}
                            </p>
                          </v-card-text>
                        </v-footer>
                      </v-slide-y-reverse-transition>
                    </v-card>
                  </template>
                </v-hover>
                <v-fade-transition mode="out-in">
                  <v-overlay
                    v-if="overlay"
                    fixed
                    opacity=".9"
                  >
                    <v-btn
                      color="transparent"
                      depressed
                      fab
                      fixed
                      large
                      right
                      top
                      @click="overlay = false"
                    >
                      <v-icon large>
                        mdi-close
                      </v-icon>
                    </v-btn>
                    <v-card tile>
                      <v-img
                        :src="`/static/${overlay}`"
                        width="80vw"
                        height="80vh"
                        max-width="90vw"
                      />
                      <!-- <v-footer
                        color="rgba(0,0,0,0.3), rgba(0,0,0,0.3)"
                        absolute
                        height="125"
                      >
                        <v-card-text style="color: white">
                          <p class="font-italic">
                            {{ plant.name }}
                          </p>
                          <p class="font-italic">
                            {{ plant.title }}
                          </p>
                          {{ plant.desc }}
                        </v-card-text>
                      </v-footer> -->
                    </v-card>
                  </v-overlay>
                </v-fade-transition>
              </v-col>
            </transition-group>
          </v-container>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mdiArrowTopRightThick } from '@mdi/js'

  export default {
    data: () => ({
      svgArrow: mdiArrowTopRightThick,
      category: null,
      categories: [
        {
          text: 'All Plants',
          filter: null
        },
        {
          text: 'Frost resistant',
          filter: 1
        },
        {
          text: 'Drought Resistant',
          filter: 2
        },
        {
          text: 'Fast Growing',
          filter: 3
        },
        {
          text: 'Bird Attracting',
          filter: 4
        }
      ],
      overlay: false,
      plants: [
        {
          name: 'White Stinkwood',
          title: 'Celtis africana',
          img: 'White-Stinkwood-Leaves.png',
          categories: [1]
        },
        {
          name: 'Coral Tree (thorns)',
          title: 'Erythrina lysistemon',
          img: 'coral-tree.jpg',
          categories: [1, 3, 4]
        },
        {
          name: 'Black Monkey Thorn',
          title: 'Senegalia burkei',
          img: 'Acacia-burkei.jpg',
          categories: [1, 2]
        },
        {
          name: 'Sweet Thorn',
          title: 'Vachelia karroo',
          img: 'sweet-thorn.jpg',
          categories: [1]
        },
        {
          name: 'Fever Tree (thorns)',
          title: 'Vachellia xanthoploea',
          img: 'fever-tree.jpg',
          categories: [1, 3]
        },
        {
          name: 'Tree Aloe',
          title: 'Aloidendron barberae',
          img: 'tree-aloe.jpg',
          categories: [1]
        },
        {
          name: 'White Pear',
          title: 'Apodytes dimidiata',
          img: 'white-pear.jpg',
          categories: [1]
        },
        {
          name: 'Pride-of-De-Kaap',
          title: 'Bauhinia galpinii',
          img: 'Bauhinia-galpinii.jpg',
          categories: [1, 2]
        },
        {
          name: 'Big Num-Num',
          title: 'Carissa Macrocarpa',
          img: 'big-num-num.jpg',
          categories: [1]
        },
        {
          name: 'Mountain Karee',
          title: 'Searsia leptodictya',
          img: 'mountian-karee.jpg',
          categories: [1]
        },
        {
          name: 'Russet Bushwillow',
          title: 'Combretum hereroense',
          img: 'russet-bushwillow.jpg',
          categories: [1, 2]
        },
        {
          name: 'Jacket Plum',
          title: 'Pappea capensis',
          img: 'jacket-plum.jpg',
          categories: [1, 4]
        },
        {
          name: 'Kei Apple (has thorns)',
          title: 'Dovyalis cafra',
          img: 'kei-apple.jpg',
          categories: [1, 4]
        },
        {
          name: 'Puzzlebush',
          title: 'Ehretia rigidia',
          img: 'puzzlebush.jpg',
          categories: [1, 4]
        },
        {
          name: 'Cape Ash',
          title: 'Ekebergia capensis',
          img: 'cape-ash.jpg',
          categories: [1, 3]
        },
        {
          name: 'Wild Date Palm',
          title: 'Phoenix reclinata',
          img: 'wild-date-palm.jpg',
          categories: [1, 4]
        },
        {
          name: 'Impala Lily',
          title: 'Adenium multiflorum',
          img: 'impala-lily.jpg',
          categories: [2]
        },
        {
          name: 'Pod Mahogany',
          title: 'Afzelia quanzensis',
          img: 'pod-mahogany.jpg',
          categories: [2]
        },
        {
          name: 'Natal Gardenia',
          title: 'Gardenia thunburgia',
          img: 'natal-gardenia.jpg',
          categories: [2]
        },
        {
          name: 'Coast Silver Oak',
          title: 'Brachylaena discolour',
          img: 'coast-silver-oak.jpg',
          categories: [2]
        },
        {
          name: 'Red-leaved Rock Fig',
          title: 'Ficus ingens',
          img: 'red-leaf-rock-fig.jpg',
          categories: [2]
        },
        {
          name: 'Weeping Boer-bean',
          title: 'Schotia brachypetala',
          img: 'schotia-brachypetala.jpg',
          categories: [2, 4]
        },
        {
          name: 'Yellow bauhinia',
          title: 'Bauhinia tomentosa',
          img: 'yellow-bauhinia.jpg',
          categories: [2]
        },
        {
          name: 'Weeping Wattle',
          title: 'Peltophorum africanum',
          img: 'weeping_wattle.jpg',
          categories: [2]
        },
        {
          name: 'Tamboti',
          title: 'Spirostachys africana',
          img: 'tamboti.jpg',
          categories: [2]
        },
        {
          name: 'Dune Poison-bush',
          title: 'Acokanthera oblongifolia',
          img: 'dune-poison-bush.jpg',
          categories: [2]
        },
        {
          name: 'Sneezewood',
          title: 'Ptaeroxylon obliquum',
          img: 'sneezewood.jpg',
          categories: [2]
        },
        {
          name: 'Gingerbush',
          title: 'Tetradenia riparia',
          img: 'gingerbush.jpg',
          categories: [2]
        },
        {
          name: 'Marula',
          title: 'Sclerocarya birrea',
          img: 'marula.jpg',
          categories: [2]
        },
        {
          name: 'All aloe species',
          title: 'Vernonia colorata',
          img: 'aloe-varieties.jpg',
          categories: [2, 4]
        },
        {
          name: 'Lowveld bitter-tea',
          title: 'Vernonia colorata',
          img: 'lowveld-bitter-tea.jpg',
          categories: [2]
        },
        {
          name: 'Pigeonwood',
          title: 'Trema orientalis',
          img: 'pigeonwood.jpg',
          categories: [3]
        },
        {
          name: 'Flatcrown',
          title: 'Albizia adianthifolia',
          img: 'flatcrown.jpg',
          categories: [3]
        },
        {
          name: 'Red Beech',
          title: 'Protorhus longifilia',
          img: 'red-beech.jpg',
          categories: [3]
        },
        {
          name: 'Natal Fig',
          title: 'Ficus natalensis',
          img: 'natal-fig.jpg',
          categories: [3]
        },
        {
          name: 'Giant-leaved Fig',
          title: 'Ficus lutea',
          img: 'giant-leaved-fig.jpg',
          categories: [3]
        },
        {
          name: 'Mitzeeri',
          title: 'Bridelia micrantha',
          img: 'lowveld-bitter-tea.jpg',
          categories: [3, 4]
        },
        {
          name: 'Waterberry',
          title: 'Syzgium cordatum',
          img: 'waterberry.jpg',
          categories: [3, 4]
        },
        {
          name: 'Wild Mango',
          title: 'Cordyla africana',
          img: 'wild-mango.jpg',
          categories: [3]
        },
        {
          name: 'Quinine Tree',
          title: 'Rauvolfia caffra',
          img: 'quinine.jpg',
          categories: [3, 4]
        },
        {
          name: 'Natal Mahogany',
          title: 'Trichilia emetica',
          img: 'natal-mahogany.jpg',
          categories: [3]
        },
        {
          name: 'Tasselberry',
          title: 'Antidesma venosum',
          img: 'tasselberry.jpg',
          categories: [3, 4]
        },
        {
          name: 'Toad Trees',
          title: 'Tabernaemontana spp',
          img: 'toad-trees.jpg',
          categories: [3, 4]
        },
        {
          name: 'BlackBird-Berry',
          title: 'Psychotria capensis',
          img: 'lowveld-bitter-tea.jpg',
          categories: [2]
        },
        {
          name: 'Brown Ivory',
          title: 'Berchemia discolour',
          img: 'brown-ivory.jpg',
          categories: [4]
        },
        {
          name: 'Num-num',
          title: 'Carissa macrocarpa',
          img: 'lowveld-bitter-tea.jpg',
          categories: [4]
        },
        {
          name: 'Large-leaf Dragontree',
          title: 'Dracaena aletriformis',
          img: 'large-leaf-dragontree.jpg',
          categories: [4]
        },
        {
          name: 'Snuff Box Tree (has thorns)',
          title: 'Oncoba spinosa',
          img: 'snuff-box-tree.jpg',
          categories: [4]
        },
        {
          name: 'Tree Fuchsia',
          title: 'Halleria lucida',
          img: 'tree-fuchsia.jpg',
          categories: [4]
        },
        {
          name: 'Cape Honeysuckle',
          title: 'Tecoma capensis',
          img: 'cape-honeysuckle.jpg',
          categories: [4]
        },
        {
          name: 'Fig Tree',
          title: 'Ficus species',
          img: 'fig-tree.jpg',
          categories: [4]
        },
        {
          name: 'Triangle Tops',
          title: 'Blighia unijugata',
          img: 'triangle-tops.jpg',
          categories: [4]
        },
        {
          name: 'Glossy White Ash',
          title: 'Bersama lucens',
          img: 'glossy-white-ash.jpg',
          categories: [4]
        },
        {
          name: 'Turkeyberry',
          title: 'Canthium inerme',
          img: 'turkeyberry.jpg',
          categories: [4]
        }
      ]
    }),

    computed: {
      computedplants () {
        return !this.category
          ? this.plants
          : this.plants.filter((p) => p.categories.includes(this.category))
      }
    },

    methods: {
      select (category) {
        this.category = category.filter
      }
    }
  }
</script>

<style scoped>
.gallery-card {
  transform-origin: center center 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.fade-transition-leave,
.fade-transition-leave-active,
.fade-transition-leave-to {
  display: none;
}
</style>
